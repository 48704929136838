<script setup lang="ts">
import { mdiCog, mdiFinance, mdiLogout, mdiWallet } from "@mdi/js"

const appConfig = {
  title: "",
}

const { isMobile } = useDevice()

useHead({
  title: appConfig.title,
})

const user = useOptionalSessionUser()

const sess = useAuth()
</script>

<template>
  <v-app-bar app density="compact">
    <v-container class="py-0">
      <div v-if="isMobile" class="d-flex align-center">
        <div style="width: 33%">
          <v-app-bar-nav-icon @click="$emit('toggleDrawer')" />
        </div>
        <div style="width: 33%" class="d-flex justify-center">
          <NuxtLink :to="{ name: 'index' }" class="align-self-center">
            <span class="main-link">
              <img src="@/assets/svg.svg" alt="get signals logo" />
              {{ appConfig.title }}
            </span>
          </NuxtLink>
        </div>

        <div style="width: 33%"></div>
      </div>
      <div v-else class="d-flex justify-space-between align-center">
        <div class="d-flex align-center">
          <NuxtLink :to="{ name: 'index' }">
            <span class="main-link">
              <img src="@/assets/svg.svg" alt="get signals logo" />
              {{ appConfig.title }}
            </span>
          </NuxtLink>
        </div>
        <div class="d-flex">
          <NuxtLink
            class="d-flex align-center ml-8"
            :to="{ name: 'portfolio' }"
          >
            <v-icon :icon="mdiWallet" />
            {{ trans("header.myPortfolio") }}
          </NuxtLink>
          <NuxtLink
            class="d-flex align-center ml-8"
            :to="{ name: 'securities' }"
          >
            <v-icon :icon="mdiFinance" />
            {{ trans("header.securities") }}
          </NuxtLink>
          <NuxtLink class="d-flex align-center ml-8" :to="{ name: 'settings' }">
            <v-icon :icon="mdiCog" />
            {{ trans("body.settings") }}
          </NuxtLink>
          <NuxtLink
            class="d-flex align-center ml-8"
            :to="{ name: 'index' }"
            @click="() => (user ? sess.signOut() : sess.signIn('auth0'))"
          >
            <v-icon :icon="mdiLogout" />
            {{ user ? "Logout" : "Login" }}
          </NuxtLink>
        </div>
      </div>
    </v-container>
  </v-app-bar>
</template>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Orbitron&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

a,
a:visited,
a:hover,
a:active {
  padding-bottom: 4px;
  color: inherit;
  font-family: "Montserrat";
}

.log-btn {
  font-family: "Montserrat";
}

a:not(:active) {
  text-decoration: none;
}

a.router-link-exact-active {
  color: rgb(var(--v-theme-primary));
  border-bottom: 1px solid rgb(var(--v-theme-primary));
}

span.main-link {
  img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  font-family: "Orbitron", sans-serif;
  color: white;
  display: flex;
  align-items: center;
}
</style>